import isToday from 'date-fns/isToday'
import roundToNearestMinutes from 'date-fns/roundToNearestMinutes'
import {setHMToDate, getScheduleFromCalendarForDate} from '@wix/table-reservations-lib/schedule'
import {BusinessSchedule} from '@wix/ambassador-table-reservations-v1-reservation-location/types'

import {getScheduleForDate, getScheduleForMonth} from './businessSchedule'
import {generateTimeOptionsBasedOnDaySchedule, TimeOption} from './timeOptions'

export const getNearestWorkingDate = ({
  businessSchedule,
  regionalSettings,
  timeZone,
}: {
  businessSchedule: BusinessSchedule
  regionalSettings?: string
  timeZone?: string | null
}) => {
  let timeOption: TimeOption | undefined
  let nearestWorkingDayString: string | undefined

  const businessScheduleForMonth = getScheduleForMonth({
    businessSchedule,
    timeZone,
  })

  Object.keys(businessScheduleForMonth).some((dayString) => {
    const availableSlotsForDay = businessScheduleForMonth[dayString].schedule

    if (!availableSlotsForDay.length) {
      return false
    }

    const timeOptions = generateTimeOptionsBasedOnDaySchedule(
      new Date(dayString),
      availableSlotsForDay,
      regionalSettings,
    )

    if (timeOptions.length) {
      timeOption = timeOptions[0]
      nearestWorkingDayString = dayString

      return true
    }

    return false
  })

  if (nearestWorkingDayString && timeOption) {
    const {hours, minutes} = timeOption.data

    return setHMToDate(new Date(nearestWorkingDayString), hours, minutes)
  }

  return getNearestQuarterTime(new Date())
}

export const filterDate = ({
  date,
  businessSchedule,
  regionalFormat,
  timeZone,
}: {
  date: Date
  businessSchedule: BusinessSchedule
  regionalFormat?: string
  timeZone?: string | null
}) => {
  const scheduleForDate = getScheduleForDate({date, businessSchedule, timeZone})
  const schedule = getScheduleFromCalendarForDate(date, scheduleForDate)

  if (isToday(date)) {
    const timeOptions = generateTimeOptionsBasedOnDaySchedule(date, schedule, regionalFormat)

    return !!timeOptions.length
  }

  return !!schedule?.length
}

export const getNearestQuarterTime = (date: Date) =>
  roundToNearestMinutes(date, {nearestTo: 15, roundingMethod: 'ceil'})
